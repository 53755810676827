import terms from 'assets/terms'
import { ModuleSelect } from 'components'
import { useNavigate } from 'react-router-dom'
import { routes, useBreadcrumb } from 'services'
import { useAppSelector } from 'utils'

export default function HomePage() {
  useBreadcrumb()
  const navigate = useNavigate()
  const { ihms } = useAppSelector(state => state.app)

  const handleSelect = (ihm: string) => () => {
    navigate(routes.moduleSelection(ihm))
  }

  return (
    <div id="homePage" className="page">
      <ModuleSelect
        title={terms.Selections.Perimeter.title}
        description={terms.Selections.Perimeter.description}
        data={ihms.map(ihm => ({
          id: ihm.site_slug,
          name: ihm.ihm_name,
          tag: ihm.generation,
        }))}
        buttonText={terms.Common.continue}
        handleSelect={handleSelect}
      />
    </div>
  )
}
