import { Roles } from 'types'
import { useAppSelector } from 'utils'

/**
 * Roles Hook
 *
 * Add roles logic to the app here, based on cerbere permissions or other role-based access control
 *
 * @returns rolesLoaded: boolean, hasAccess: boolean
 */
export const useRoles = () => {
  const { appPermissions, isLoading, isLogged } = useAppSelector(state => state.user)

  return {
    rolesLoaded: isLogged && !isLoading,
    roles: appPermissions.filter(role => role.includes('paa::')),
    hasAccess: appPermissions.includes(Roles.ACCESS),
  }
}
