import { get, post } from '@osrdata/app_core/dist/requests'
import { IHM, Module } from 'types'
import { createApiThunk } from 'utils'

const getIHMs = createApiThunk(
  'app/getIHMs',
  () => get<IHM[]>('/paa_mng/ihm/'),
)

const postIHMDate = createApiThunk(
  'app/postIHMDate',
  (data: {slug: string, date: Module}) => post<IHM>(
    `/paa_mng/ihm/${data.slug}`,
    { dates_rejeu: data.date },
  ),
)

export {
  getIHMs,
  postIHMDate,
}
