import { ActionCreatorWithOptionalPayload, createAsyncThunk } from '@reduxjs/toolkit'
import { ToastLevel, ToastSignal } from 'components'

/**
 * Produce a generic function to create an thunk that will handle error on api call
 * @param type thunk type
 * @param apiFunction callback api function
 * @param successAction action to dispatch on success
 * @param errorAction optional action to dispatch on error
 * @returns a thunk
 */
export function createApiThunk<Type, Params=void>(
  type: string,
  apiFunction: (params: Params) => Promise<Type>,
  successAction?: ActionCreatorWithOptionalPayload<Type, string>,
  successMessage?: string,
  errorAction?: ActionCreatorWithOptionalPayload<Type, string>,
) {
  return createAsyncThunk(
    type,
    async (params: Params, thunkApi) => {
      try {
        const response = await apiFunction(params)
        if (successMessage) {
          ToastSignal.value = { message: successMessage, severity: ToastLevel.SUCCESS }
        }
        if (successAction) thunkApi.dispatch(successAction(response))
        return response
      } catch (e) {
        if (errorAction) thunkApi.dispatch(errorAction(e))
        return thunkApi.rejectWithValue(e)
      }
    },
  )
}
