import { ArrowForwardIos } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { routes } from 'services'
import { BreadcrumbElement } from 'types'
import terms from 'assets/terms'

import './Breadcrumbs.scss'

interface Props {
  breadcrumbs?: BreadcrumbElement[]
}

export default function Breadcrumb({ breadcrumbs = [] }: Props) {
  const navigate = useNavigate()

  return (
    <div className="breadcrumb">
      <button type="button" onClick={() => navigate(routes.home)}>
        {terms.Pages.Home.title}
      </button>
      {breadcrumbs.map(({ path, render }) => (
        <div key={path} className="flex-center">
          <ArrowForwardIos />
          <button type="button" onClick={() => navigate(path)}>
            {render}
          </button>
        </div>
      ))}
    </div>
  )
}
