/* eslint-disable camelcase */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useAppSelector } from 'utils'
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg'
import { ReactComponent as PauseIcon } from 'assets/icons/pause.svg'
import { ReactComponent as ForwardIcon } from 'assets/icons/forward.svg'
import { ReactComponent as BackwardIcon } from 'assets/icons/backward.svg'
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg'
import { ReplaySignal } from './ModuleReplayPage'

import './TimeTools.scss'

type SpeedRange = 1 | 2 | 4 | 8

export default function TimeTools() {
  const progressRef = useRef<HTMLDivElement>(null)
  const draggingRef = useRef<boolean>(false)
  const { ihmSlug } = useParams()
  const { dates_rejeu } = useAppSelector(state => state.app.ihms.find(({ site_slug }) => site_slug === ihmSlug))
  const [progressWidth, setProgressWidth] = useState<number>(0)
  const [speed, setSpeed] = useState<SpeedRange>(1)
  const durationInSeconds = dates_rejeu.duration * 60 * 60

  /**
   * Get the current time based on the progress width with the given format
   *
   * @param progress - progress width in percentage
   * @param format - format to use for the time
   * @returns - formatted time as a string
   */
  const getCurrentTime = (
    progress: number,
    format: string,
  ) => moment(dates_rejeu.start_time).add(durationInSeconds * (progress / 100), 'seconds').format(format)

  /**
   * Send the new timestamp to the backend
   *
   * @param progress - progress width in percentage
   */
  const handleSendTimestamp = (progress: number) => {
    const newTime = getCurrentTime(progress, 'YYYY-MM-DDTHH:mm:ss.SSS')
    ReplaySignal.message.value = { message_type: 'JUMP_TO_TIMESTAMP', timestamp: newTime }
  }

  /**
   * Update the progress width based on the number of seconds to add
   * Then notify the backend if needed
   *
   * @param seconds - number of seconds to add to the current progress
   * @param shouldSendMessage - if true, send timestamp update to the backend
   */
  const handleTimeUpdate = (seconds: number, shouldSendMessage = true) => () => {
    setProgressWidth(prevProgress => {
      const progress = Math.max(0, Math.min(100, prevProgress + (seconds / durationInSeconds) * 100))
      if (shouldSendMessage) handleSendTimestamp(progress)
      return progress
    })
  }

  /**
   * Update the progress width based on the click or drag position
   * Then notify the backend if drag has ended (Drag is always false after a click)
   */
  const handleProgressBarUpdate = useCallback((e: MouseEvent | React.MouseEvent<HTMLDivElement>) => {
    const { left, width } = progressRef.current.getBoundingClientRect()
    const clickX = e.clientX - left
    const progress = (clickX / width) * 100
    setProgressWidth(Math.max(0, Math.min(100, progress)))
    if (!draggingRef.current) handleSendTimestamp(progress)
  }, [])

  const handleSpeedUpdate = () => {
    const newSpeed = speed === 8 ? 1 : speed * 2
    setSpeed(newSpeed as SpeedRange)
    ReplaySignal.message.value = { message_type: 'REPLAY_SPEED', speed: newSpeed }
  }

  const handlePauseToggle = () => {
    ReplaySignal.status.value = ReplaySignal.status.value === 'play' ? 'pause' : 'play'
    ReplaySignal.message.value = { message_type: 'PLAY_PAUSE', pause: ReplaySignal.status.value === 'pause' }
  }

  // Dragging and keybinding events handlers
  const handleMouseUp = () => { draggingRef.current = false }
  const handleMouseDown = () => { draggingRef.current = true }
  const handleMouseMove = (e: MouseEvent) => draggingRef.current && handleProgressBarUpdate(e)
  const handleKeyPress = (e: KeyboardEvent) => e.key === ' ' && handlePauseToggle()
  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowRight') handleTimeUpdate(15)()
    if (e.key === 'ArrowLeft') handleTimeUpdate(-15)()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (ReplaySignal.status.value === 'pause') return
      handleTimeUpdate(1, false)()
    }, 1000 / speed)
    return () => clearInterval(interval)
  }, [ReplaySignal.status.value, progressWidth])

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
    document.addEventListener('keypress', handleKeyPress)
    document.addEventListener('keydown', handleOnKeyDown)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('keypress', handleKeyPress)
      document.removeEventListener('keydown', handleOnKeyDown)
    }
  }, [])

  return (
    <div className="time-tools">
      <div className="tools">
        <div className="actions">
          <div className="update-time-button" onClick={handleTimeUpdate(-15)}>
            <BackwardIcon />
            <span>15</span>
          </div>
          <div className="pause-button">
            {ReplaySignal.status.value === 'play'
              ? <PauseIcon onClick={handlePauseToggle} />
              : <PlayIcon onClick={handlePauseToggle} />}
          </div>
          <div className="update-time-button" onClick={handleTimeUpdate(15)}>
            <ForwardIcon />
            <span>15</span>
          </div>
        </div>
        <div className="current-time">
          <span>{getCurrentTime(progressWidth, 'HH:mm:ss')}</span>
          <TimeIcon />
        </div>
        <div className="speed" onClick={handleSpeedUpdate}>
          <span>{`x${speed}`}</span>
        </div>
      </div>
      <div className="progress-container">
        <div ref={progressRef} className="progress" onClick={handleProgressBarUpdate}>
          <div className="progress-bar" style={{ width: `${progressWidth}%` }}>
            <div className="thumb" onMouseDown={handleMouseDown} />
          </div>
        </div>
        <div className="time-indicators">
          <span>{moment(dates_rejeu.start_time).format('HH:mm:ss')}</span>
          <span>{moment(dates_rejeu.start_time).add(dates_rejeu.duration, 'hours').format('HH:mm:ss')}</span>
        </div>
      </div>
    </div>
  )
}
