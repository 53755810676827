import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from 'types'
import { getIHMs, postIHMDate } from './thunks'

const initialState: AppState = {
  error: null,
  fullscreen: false,
  ihms: [],
  loadingIHM: true,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleFullscreen: state => {
      state.fullscreen = !state.fullscreen
    },
    getError: (state, action: PayloadAction<null>) => {
      state.error = action.payload
    },
    resetIhmPeriod: (state, action: PayloadAction<string>) => {
      state.ihms = state.ihms.map(ihm => {
        if (ihm.site_slug === action.payload) {
          ihm.dates_rejeu = null
        }
        return ihm
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(getIHMs.pending, state => {
      state.loadingIHM = true
    })
    builder.addCase(getIHMs.fulfilled, (state, action) => {
      state.ihms = action.payload.map(ihm => {
        ihm.dates_rejeu = null
        return ihm
      })
      state.loadingIHM = false
    })
    builder.addCase(getIHMs.rejected, state => {
      state.loadingIHM = false
    })
    builder.addCase(postIHMDate.pending, state => {
      state.loadingIHM = true
    })
    builder.addCase(postIHMDate.fulfilled, (state, action) => {
      state.ihms = state.ihms.map(ihm => {
        if (ihm.site_slug === action.payload.site_slug) {
          return action.payload
        }
        return ihm
      })
      state.loadingIHM = false
    })
    builder.addCase(postIHMDate.rejected, state => {
      state.loadingIHM = false
    })
  },
})

export const {
  toggleFullscreen,
  getError,
  resetIhmPeriod,
} = appSlice.actions

export default appSlice.reducer
