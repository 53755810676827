/* eslint-disable max-len */
export const terms = {
  Common: {
    loading: 'Chargement',
    updated: 'Modifié',
    sortBy: 'Trier par',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    continue: 'Continuer',
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
    httpStatus: {
      forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
      notFound: 'La ressource demandée n\'existe pas.',
      internalServerError: 'Erreur de traitement de la requête côté serveur.',
      badGateway: 'Le serveur est indisponible.',
      unknownError: 'Une erreur inconnue est survenue.',
      badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
    },
  },
  SideMenu: {
    links: {
      myViz: 'Mes visualisations',
      help: 'Demande d’assistance',
    },
  },
  Pages: {
    Home: {
      title: 'Page d\'accueil',
    },
  },
  Selections: {
    Perimeter: {
      title: 'Choix du périmètre',
      description: 'Veuillez sélectionner le périmètre de votre analyse',
    },
    ModuleChoice: {
      archive: 'Archives',
      analytic: 'Module Analytique',
      replay: 'Rejeu vidéo',
      cdvStats: 'Statistiques CdV',
      volume: 'Volumétrie',
      title: 'Choix de la fonctionnalité',
      description: 'Veuillez sélectionner le module d\'analyse',
    },
    Period: {
      title: 'Choix de la période',
      missingDataDays: 'Données manquantes pour ces journées',
      missingDataDay: 'Données manquantes pour cette journée',
      buttonText: 'Choisir cette plage',
      startTime: 'Heure de début',
      endTime: 'Heure de fin',
      availableData: '2 mois de données disponibles',
      update: 'Changer la plage temporelle',
      PeriodReplay: {
        description: 'Veuillez sélectionner la journée à rejouer',
        buttonText: 'Générer la vidéo sur cette journée',
        durationLabel: 'Durée de la vidéo',
      },
    },
  },
  Modals: {},
  Messages: {
    success: {
    },
    errors: {
    },
  },
} as const

export default terms
