import { useState } from 'react'
import { Calendar } from 'react-date-range'
import moment from 'moment'
import { addDays } from 'date-fns'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { fr } from 'date-fns/locale'
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import SimpleButton from 'components/button/Button'

import './Period.scss'

interface PeriodProps {
  title: string;
  availableData?: string;
  description?: string;
  onSelect: (date: Date, duration: number) => void;
  startTimeLabel?: string;
  durationLabel?: string;
  buttonText: string;
}

export default function Period({
  title,
  availableData,
  description,
  onSelect,
  startTimeLabel,
  durationLabel,
  buttonText,
}: PeriodProps) {
  const [startTime, setStartTime] = useState<Date | null>(new Date())
  const [duration, setDuration] = useState<number>(1)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const minDate = addDays(new Date(), -60)

  const handleSelect = (date: Date) => {
    setSelectedDate(date)
  }

  const handleDurationChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDuration(Number.parseInt(event.target.value, 10))
  }

  const handleButtonClick = () => {
    if (!selectedDate) return

    onSelect(
      moment(selectedDate).set({
        hour: startTime?.getHours() || 0,
        minute: startTime?.getMinutes() || 0,
        second: 0,
        millisecond: 0,
      }).toDate(),
      duration,
    )
  }

  return (
    <div className="period-wrapper">
      <div className="period">
        <div className="header">
          <h2>{title}</h2>
          <p className="available-data">{availableData}</p>
          <p className="description">{description}</p>
        </div>
        <div className="calendar-wrapper">
          <Calendar
            date={selectedDate}
            locale={fr}
            onChange={handleSelect}
            showDateDisplay={false}
            color="#1775E0"
            fixedHeight
            minDate={minDate}
            maxDate={new Date()}
          />
        </div>
        <div className="time-duration-wrapper">
          <div className="time-picker">
            <label htmlFor="start-time-picker">
              {startTimeLabel}
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                value={startTime}
                onChange={setStartTime}
                ampm={false}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="duration-picker">
            <label htmlFor="duration-select">
              {durationLabel}
            </label>
            <div className="select-wrapper">
              <select
                className="select"
                id="duration-select"
                value={duration}
                onChange={handleDurationChange}
              >
                {[...Array(24).keys()].map(time => (
                  <option key={time} value={time + 1}>
                    {`${time + 1}h`}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <SimpleButton
          text={buttonText}
          onClick={handleButtonClick}
        />
      </div>
    </div>
  )
}
