import { useState } from 'react'
import SimpleButton from 'components/button/Button'
import './ModuleSelect.scss'

interface Props {
  title: string;
  description: string;
  data: Option[];
  handleSelect: (id: string) => () => void;
  buttonText: string;
}

interface Option {
  id: string;
  name: string;
  tag?: string;
  inConstruction?: boolean;
}

export default function ModuleSelect({ title, description, data, handleSelect, buttonText }: Props) {
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  const handleOptionClick = (id: string) => {
    setSelectedOption(id)
  }

  return (
    <div className="module-select">
      <div className="section-header">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <ul className="option-list">
        {data.map(option => (
          <li key={option.id} className="list-item">
            <button
              type="button"
              className={`transparent-button ${selectedOption === option.id ? 'selected' : ''}`}
              onClick={() => handleOptionClick(option.id)}
              disabled={option.inConstruction}
            >
              <div className="title-info">
                <span>{option.name}</span>
                {option.inConstruction && <span style={{ fontSize: 14 }}>(En cours de construction)</span>}
                {option.tag && <span className={`tag ${option.tag.toLowerCase()}`}>{option.tag}</span>}
              </div>
            </button>
          </li>
        ))}
      </ul>
      <SimpleButton
        text={buttonText}
        onClick={selectedOption && handleSelect(selectedOption)}
        disabled={!selectedOption}
      />
    </div>
  )
}
