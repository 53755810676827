export enum IHMType {
  ag = 'AG',
  ng = 'NG',
}

export enum AvailableModules {
  analytic = 'analytic',
  archive = 'archive',
}

export enum AvailableSubmodules {
  replay = 'replay',
  cdvStats = 'cdvStats',
  volume = 'volume',
}

export type Module = {
  start_time: string | null,
  duration: number | null,
}

export type IHM = {
  ihm_name: string,
  site_slug: string,
  site_name: string,
  generation: IHMType,
  version: string,
  dates_rejeu: Module,
}

export type AppState = {
  error: null | undefined,
  fullscreen: boolean,
  ihms: IHM[],
  loadingIHM: boolean,
}
