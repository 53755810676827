import terms from 'assets/terms'
import { Breadcrumbs, IhmBreadCrumb, TopbarSignal } from 'components'
import { useParams } from 'react-router-dom'
import { AvailableSubmodules, BreadcrumbElement } from 'types'
import { useAppSelector } from 'utils'
import { useEffect } from 'react'

export const routes = {
  home: '/',

  moduleSelection: (ihm?: string) => {
    const ihmSlug = ihm || ':ihmSlug'
    return `/${ihmSlug}/select-module`
  },

  submoduleSelection: (ihm?: string, module?: string) => {
    const ihmSlug = ihm || ':ihmSlug'
    const moduleSlug = module || ':moduleSlug'
    return `/${ihmSlug}/${moduleSlug}/select-submodule`
  },

  module: (ihm?: string, module?: string) => (submodule: string) => {
    const ihmSlug = ihm || ':ihmSlug'
    const moduleSlug = module || ':moduleSlug'
    return `/${ihmSlug}/${moduleSlug}/${submodule}`
  },
}

/**
 * Hook used in pages to set the breadcrumb value based on the current route and params
 *
 * @param submodule - Optional submodule to add to the breadcrumb path
 */
export const useBreadcrumb = (submodule?: AvailableSubmodules) => {
  const { ihmSlug, moduleSlug } = useParams()
  const { ihms } = useAppSelector(state => state.app)
  const activeIHM = ihms.find(ihm => ihm.site_slug === ihmSlug)

  const breadcrumbs: BreadcrumbElement[] = [
    ihmSlug && {
      path: routes.moduleSelection(ihmSlug),
      render: <IhmBreadCrumb ihm={activeIHM} />,
    },
    (ihmSlug && moduleSlug) && {
      path: routes.submoduleSelection(ihmSlug, moduleSlug),
      render: terms.Selections.ModuleChoice[moduleSlug],
    },
    (ihmSlug && moduleSlug && submodule) && {
      path: routes.module(ihmSlug, moduleSlug)(submodule),
      render: terms.Selections.ModuleChoice[submodule],
    },
  ].filter(Boolean)

  useEffect(() => {
    TopbarSignal.value = <Breadcrumbs breadcrumbs={breadcrumbs} />
  }, [])
}
