import { MouseEvent, ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { RootState } from 'reducers/store'
import ACCOUNT_MENU_ITEMS from '../Item'

import './MenuAccount.scss'

export default function AccountMenu(): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const user = useSelector((state: RootState) => state.user)
  const { t } = useTranslation()
  const isMenuOpen = Boolean(anchorEl)

  const handleButtonClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleMenuClose = (next?: () => void) => {
    setAnchorEl(null)
    if (next !== undefined) next()
  }

  return (
    <div>
      <Button onClick={handleButtonClick} endIcon={<KeyboardArrowDownIcon />}>
        {`${user.account.firstName} ${user.account.lastName}`}
      </Button>
      <Menu open={isMenuOpen} anchorEl={anchorEl} onClose={() => handleMenuClose()}>
        {ACCOUNT_MENU_ITEMS.map(item => (item.show && (
        <MenuItem className="flex-center" key={item.label} onClick={() => handleMenuClose(item.onClick)}>
          {item.icon && (
          <div className="menu-item-icon flex-center">
            {item.icon}
          </div>
          )}
          <div className="menu-item-title">
            {t(item.label)}
          </div>
        </MenuItem>
        )))}
      </Menu>
    </div>
  )
}
