/* eslint-disable camelcase */
import { IHM } from 'types'

type Props = {
  ihm: IHM
}

export default function IhmBreadCrumb({ ihm }: Props) {
  const { ihm_name, generation } = ihm

  return (
    <>
      <span>{ihm_name}</span>
      <span className={`ihm-tag ${generation.toLowerCase()}`}>{generation}</span>
    </>
  )
}
