/* eslint-disable camelcase */
import { Button, ButtonStyle } from 'components'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import terms from 'assets/terms'
import { useAppDispatch, useAppSelector } from 'utils'
import { ReactComponent as ReloadIcon } from 'assets/icons/reload.svg'
import { resetIhmPeriod } from 'reducers/app'

import './PeriodIndicator.scss'

export default function PeriodIndicator() {
  const dispatch = useAppDispatch()
  const { ihmSlug } = useParams()
  const { dates_rejeu } = useAppSelector(
    state => state.app.ihms.find(({ site_slug }) => site_slug === ihmSlug),
  )

  if (!dates_rejeu?.start_time) return null

  return (
    <div className="period-indicator">
      <p>{moment(dates_rejeu.start_time).format('DD MMMM YYYY à HH:mm')}</p>
      <Button
        text={terms.Selections.Period.update}
        style={ButtonStyle.light}
        startIcon={<ReloadIcon />}
        onClick={() => dispatch(resetIhmPeriod(ihmSlug))}
      />
    </div>
  )
}
