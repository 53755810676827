import { signal } from '@preact/signals-react'
import { PORTAL_URL } from 'config/config'
import dgexLogo from 'assets/logos/dgex.svg'
import AccountMenu from './menu/MenuAccount'

import './TopBar.scss'

export const TopbarSignal = signal<JSX.Element>(null)

type Props = {
  appName: string;
}

export default function TopBar({ appName }: Props) {
  return (
    <header>
      <div className="logos">
        <a className="flex-center" href={PORTAL_URL.url}>
          <img alt={appName} src={dgexLogo} />
        </a>
        <h1>{appName}</h1>
      </div>
      {TopbarSignal.value}
      <AccountMenu />
    </header>
  )
}
