import { ReactElement } from 'react'
import Button from '@mui/material/Button'
import './Button.scss'

export enum ButtonStyle {
  primary = 'primary',
  light = 'light',
  borderLess = 'borderLess',
}

type Props = {
  text: string;
  onClick?: () => void;
  style?: ButtonStyle;
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  className?: string;
}

export default function SimpleButton({
  text, onClick,
  style = ButtonStyle.primary,
  disabled = false,
  startIcon = null,
  endIcon = null,
  className = '',
}: Props): ReactElement {
  return (
    <Button
      className={`simple-button button--${style}${disabled ? ' disabled' : ''} ${className}`}
      variant="contained"
      size="large"
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {text}
    </Button>
  )
}
