import terms from 'assets/terms'
import { ModuleSelect } from 'components'
import { useNavigate, useParams } from 'react-router-dom'
import { routes, useBreadcrumb } from 'services'
import { AvailableModules } from 'types'

export default function SelectModulesPage() {
  useBreadcrumb()
  const navigate = useNavigate()
  const { ihmSlug } = useParams()

  const handleSelect = (module: string) => () => {
    navigate(routes.submoduleSelection(ihmSlug, module))
  }

  return (
    <div id="moduleSelectPage" className="page">
      <ModuleSelect
        title={terms.Selections.ModuleChoice.title}
        description={terms.Selections.ModuleChoice.description}
        data={[
          {
            id: AvailableModules.archive,
            name: terms.Selections.ModuleChoice.archive,
            inConstruction: true,
          },
          {
            id: AvailableModules.analytic,
            name: terms.Selections.ModuleChoice.analytic,
            inConstruction: false,
          },
        ]}
        buttonText={terms.Common.continue}
        handleSelect={handleSelect}
      />
    </div>
  )
}
