import terms from 'assets/terms'
import { ToastLevel, ToastSignal } from 'components'

/**
 * Get the error message from an error object
 * @param error - The error object
 * @returns string
 */
const getErrorMessage = (error: {status: number}) => {
  switch (error?.status) {
    case 400:
      return terms.Error.httpStatus.badRequest
    case 401:
      return terms.Error.httpStatus.forbidden
    case 403:
      return terms.Error.httpStatus.forbidden
    case 404:
      return terms.Error.httpStatus.notFound
    case 502:
      return terms.Error.httpStatus.badGateway
    default:
      return terms.Error.httpStatus.unknownError
  }
}

/**
 * Catch errors from an async function and display a toast error message
 *
 * @param fn - The function to catch errors from
 * @param options - Options to customize the behavior
 * @param options.displayToast - Display a toast error message
 * @param options.errorMessage - The error message to display
 * @param options.loadingSetter - A function to set the loading state
 *
 * @returns The result of the function or an error object
 */
export const catchable = async<T> (
  fn: () => Promise<T>,
  options?: {
    displayToast?: boolean,
    errorMessage?: string,
    loadingSetter?: (value: boolean) => void,
  },
) => {
  const { displayToast = true, errorMessage, loadingSetter } = options || {}
  loadingSetter(true)
  try {
    return await fn().finally(() => {
      loadingSetter(false)
    })
  } catch (e) {
    if (displayToast && e?.message !== 'canceled') {
      ToastSignal.value = {
        message: errorMessage || e?.message?.toString() || getErrorMessage(e),
        severity: ToastLevel.ERROR,
      }
    }
    loadingSetter(false)
    return { error: e }
  }
}
